@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Abel";
  src: url("/public/fonts/Abel-Regular.ttf");
}

@layer base {
  html {
    font-family: Abel;
  }
}

.ant-select-selector {
  font-size: 12px !important;
  color: #98A2B3 !important;
  padding: 0 8px 0 2px !important;
}